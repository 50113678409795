$baseGreen: #5d87ff;
$blueColor: #041ee9;
$pinkColor: #e1bbf8;
$blueBackGround: #041ee9;

$globe-width: 12px;
$globe-height: 30px;
$globe-spacing: 75px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

// body {
//     touch-action: none !important;
// }
.MuiList-root > .MuiList-root > .active {
    // background: $baseGreen !important;
    color: #fff;
}

.bg-my-blue {
    background-color: $blueColor !important;
}

.bg-my-pink {
    background-color: $pinkColor !important;
}

.color-my-blue {
    color: $blueColor !important;
}

.color-my-pink {
    color: $pinkColor !important;
}

.verticalMenuNavLi {
    & > .active {
        color: #ffffff !important;
        background-color: $blueBackGround !important;
    }
}

.programsNavContainer {
    & > .active {
        color: #ffffff !important;
        // background-color: red !important;
    }
}

// SWIPER

.market-swiper {
    overflow: initial !important;
}

.review-slider {
    padding-bottom: 8rem;
}
.review-slider > .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
}

.market-swiper > .swiper-button-prev,
.market-swiper > .swiper-button-next {
    background: #151ee991 !important;
    width: 27px;
    height: 27px;
    border-radius: 4px;
    bottom: -34px !important;

    &::after {
        font-size: 18px;
        color: #fff;
    }
}

.market-swiper > .swiper-button-prev {
    left: 40%;

    z-index: 7777;
    top: auto;
}

.market-swiper > .swiper-button-next {
    right: 40%;
    z-index: 7777;
    top: auto;
}
.market-swiper > .swiper-button-disabled {
    // display: none !important;
    background: #7279fe3c !important;
}

// SWIPER stop

.MuiPaginationItem-root .Mui-selected {
    color: red !important;
}

.MuiPagination-ul > li > .Mui-selected {
    background: #041ee9 !important;
    color: #fff;
}

// ng

.lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
    li {
        position: relative;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        //  width: $globe-width;
        // height: $globe-height;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: $globe-spacing/2;
        margin-top: 20px !important;
        display: inline-block;
        background: rgb(148, 247, 0);
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread rgb(148, 247, 0);
        animation-name: flash-1;
        animation-duration: 2s;
        &:nth-child(2n + 1) {
            background: rgb(0, 221, 255);
            box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread rgb(0, 221, 255);
            animation-name: flash-2;
            animation-duration: 0.4s;
        }
        &:nth-child(4n + 2) {
            background: rgba(247, 0, 148, 1);
            box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread rgba(247, 0, 148, 1);
            animation-name: flash-3;
            animation-duration: 1.1s;
        }
        &:nth-child(odd) {
            animation-duration: 1.8s;
        }
        &:nth-child(3n + 1) {
            animation-duration: 1.4s;
        }
        &:before {
            content: "";
            position: absolute;
            background: #d9e7dc;
            border: 1px solid #c4c4c4;
            width: ($globe-width - 2);
            height: $globe-height/5;
            border-radius: 2px;
            top: (0 - ($globe-height/6));
            left: 5px;
        }
        &:after {
            content: "";
            // top: (0 - $globe-height/2);
            top: -19px;
            left: $globe-width - 3;
            position: absolute;
            // width: $globe-spacing + 12;
            width: 97px;
            // height: ($globe-height/3 * 2);
            height: 37px;
            border-bottom: solid #e8e000 2px;
            transform: rotate(180deg);
            border-radius: 50%;
        }
        &:last-child:after {
            content: none;
        }
        &:first-child {
            // margin-left: -$globe-spacing;
            margin-left: 0;
        }
    }
}
@keyframes flash-1 {
    0%,
    100% {
        background: #69b86d;
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread #69b86d98;
    }
    50% {
        background: #2ee937;
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread #2ee937a0;
    }
}
@keyframes flash-2 {
    0%,
    100% {
        background: #5378f2;
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread #5653f2ce;
    }
    50% {
        background: #1613c2;
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread rgba(53, 50, 236, 0.672);
    }
}
@keyframes flash-3 {
    0%,
    100% {
        background: #d72a47;
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread #d72a4798;
    }
    50% {
        background: #f60029;
        box-shadow: 0px $globe-height/6 $globe-width * 2 $globe-spread #f00129a9;
    }
}

// ng stop

@media (min-width: 899px) {
    .MuiPagination-root {
        .MuiPagination-ul {
            flex-wrap: nowrap;

            li {
                > button {
                    font-size: 0.9rem;
                }
                &:first-child {
                    flex-basis: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    > button::after {
                        margin-left: 10px;
                        content: "Предыдущая";
                    }
                }
                &:last-child {
                    flex-basis: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    > button::before {
                        margin-right: 10px;
                        content: "Следующая";
                    }
                }
            }
        }
    }
}

@media (max-width: 899px) {
    .market-swiper > .swiper-button-prev {
        left: 6%;
    }

    .market-swiper > .swiper-button-next {
        right: 6%;
    }

    .market-swiper > .swiper-button-prev,
    .market-swiper > .swiper-button-next {
        background: #000cf7aa !important;
        width: 60px;
        height: 32px;
        bottom: -42px !important;
    }
}
